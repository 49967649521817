@font-face {
  font-family: Averta;
  src:
    local('AvertaStd-Regular'),
    url('../fonts/AvertaStd-Regular.woff2') format('woff2'),
    url('../fonts/AvertaStd-Regular.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: Averta;
  src:
    local('AvertaStd-RegularItalic'),
    url('../fonts/AvertaStd-RegularItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: Averta;
  src:
    local('AvertaStd-Semibold'),
    url('../fonts/AvertaStd-Semibold.woff2') format('woff2'),
    url('../fonts/AvertaStd-Semibold.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: Averta;
  src:
    local('AvertaStd-SemiboldItalic'),
    url('../fonts/AvertaStd-SemiboldItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: Averta;
  src:
    local('AvertaStd-Extrabold'),
    url('../fonts/AvertaStd-Extrabold.woff2') format('woff2'),
    url('../fonts/AvertaStd-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: Averta;
  src:
    local('AvertaStd-ExtraboldItalic'),
    url('../fonts/AvertaStd-ExtraboldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}
