* {
  box-sizing: border-box;
}

body,
html {
  display: block;
  font-family: $fontFamily;
  font-weight: $fontWeightRegular;
  color: $textBaseColor;
  line-height: 1;
  margin: 0;
  background-color: $bodyBackgroundColor;
  background-image: image-set(url('../staticImages/bg-sand-dark.webp') type("image/webp"), url('../staticImages/bg-sand-dark.jpg') type("image/jpeg")); // stylelint-disable-line
  min-height: 100vh;
  scroll-behavior: smooth; // stylelint-disable-line
  letter-spacing: .03em;
  overflow-x: hidden;

  @include fontSize($baseFontSize);

  &.no-scroll--burger {
    @media (max-width: $screen-laptop) {
      overflow: hidden;
      position: relative;
    }

    @media (min-width: $screen-desktop-sm) {
      .page-overlay--visible {
        display: none;
      }
    }
  }
}

button {
  font-family: $fontFamily;
}

input,
select,
textarea {
  font-family: $fontFamily;
}

img {
  width: auto;
  height: auto;
}

/* stylelint-disable-next-line selector-type-no-unknown */
nobr {
  white-space: normal;
}

.visually-hidden:not(:focus, :active) {
  @include visually-hidden();
}
