$icons: (
  arrowDown: "\ea01",
  arrowDownBold: "\ea02",
  arrowLeft: "\ea03",
  arrowLeftBold: "\ea04",
  arrowLineRight: "\ea05",
  arrowRight: "\ea06",
  arrowRightBold: "\ea07",
  arrowTop: "\ea08",
  arrowUp: "\ea09",
  arrowUpBold: "\ea0a",
  award: "\ea0b",
  benefitsBottle: "\ea0c",
  benefitsChecklist: "\ea0d",
  benefitsDhl: "\ea0e",
  benefitsGaranty: "\ea0f",
  benefitsPaket: "\ea10",
  benefitsService: "\ea11",
  benefitsThumb: "\ea12",
  benefitsWineglas: "\ea13",
  binoculars: "\ea14",
  bodega: "\ea15",
  bodentyp: "\ea16",
  bottleSize: "\ea17",
  burgerMenu: "\ea18",
  calendar: "\ea19",
  calendarDate: "\ea1a",
  camera: "\ea1b",
  cart: "\ea1c",
  certificate: "\ea1d",
  checkSmall: "\ea1e",
  checked: "\ea1f",
  checklist: "\ea20",
  claim: "\ea21",
  close: "\ea22",
  cloud: "\ea23",
  comment: "\ea24",
  confirmationArrow: "\ea25",
  confirmationBox: "\ea26",
  confirmationHome: "\ea27",
  confirmationMail: "\ea28",
  confirmationTracking: "\ea29",
  confirmationVersand: "\ea2a",
  cursorSelect: "\ea2b",
  delete: "\ea2c",
  delivery: "\ea2d",
  documentCheck: "\ea2e",
  edit: "\ea2f",
  eurCircle: "\ea30",
  exitFullscreenIcon: "\ea31",
  expand: "\ea32",
  eye: "\ea33",
  facebook: "\ea34",
  facebookCircle: "\ea35",
  facebookInline: "\ea36",
  facebookInverted: "\ea37",
  favorites: "\ea38",
  filter: "\ea39",
  fullscreenIcon: "\ea3a",
  giftcard: "\ea3b",
  grapeVariety: "\ea3c",
  headset: "\ea3d",
  heart: "\ea3e",
  heartFilled: "\ea3f",
  heartUnfilled: "\ea40",
  home: "\ea41",
  homeBrandy: "\ea42",
  homeCava: "\ea43",
  homeCherry: "\ea44",
  homeOffer: "\ea45",
  homePackages: "\ea46",
  homeWine: "\ea47",
  info: "\ea48",
  infoPopup: "\ea49",
  instagram: "\ea4a",
  jahresproduktion: "\ea4b",
  jubilaeum: "\ea4c",
  klima: "\ea4d",
  letter: "\ea4e",
  linkLetter: "\ea4f",
  lkw: "\ea50",
  loaderGlasA: "\ea51",
  loaderGlasB: "\ea52",
  loaderGlasC: "\ea53",
  loaderSwing: "\ea54",
  loading: "\ea55",
  logo: "\ea56",
  mail: "\ea57",
  mailInverted: "\ea58",
  mailOpen: "\ea59",
  menu: "\ea5a",
  minus: "\ea5b",
  packet: "\ea5c",
  passtZu: "\ea5d",
  phone: "\ea5e",
  pinterest: "\ea5f",
  plus: "\ea60",
  present: "\ea61",
  productBox: "\ea62",
  qfBottle: "\ea63",
  qfBrand: "\ea64",
  qfGlass: "\ea65",
  qfGrape: "\ea66",
  qfLeaf: "\ea67",
  qfLocation: "\ea68",
  qfNonAlcoholic: "\ea69",
  rebflaeche: "\ea6a",
  redwine: "\ea6b",
  region: "\ea6c",
  reload: "\ea6d",
  return: "\ea6e",
  reviews: "\ea6f",
  rosewine: "\ea70",
  search: "\ea71",
  security: "\ea72",
  share: "\ea73",
  shipping: "\ea74",
  spainMap: "\ea75",
  star: "\ea76",
  starFilled: "\ea77",
  statusDelayed: "\ea78",
  statusDelivery: "\ea79",
  statusHome: "\ea7a",
  statusOrderPacked: "\ea7b",
  statusPackage: "\ea7c",
  statusReceived: "\ea7d",
  synonyme: "\ea7e",
  talk: "\ea7f",
  taste: "\ea80",
  time: "\ea81",
  trash: "\ea82",
  twitter: "\ea83",
  twitterInline: "\ea84",
  twitterInverted: "\ea85",
  typeOfWine: "\ea86",
  uilShare: "\ea87",
  uilShareFilled: "\ea88",
  user: "\ea8b",
  userMailCheck: "\ea8c",
  vcard: "\ea8d",
  video: "\ea8e",
  vinosLogo: "\ea8f",
  voucherChf: "\ea90",
  voucherFive: "\ea91",
  weinmacher: "\ea92",
  whatsapp: "\ea93",
  whatsappInverted: "\ea94",
  whitewine: "\ea95",
  zoomIn: "\ea96",

);