@import '../../utilities/scss/teasers/teaserLayout.scss';

.grid {
  --grid-element-background-individual: transparent;

  &__element {
    &--background-individual {
      background: var(--grid-element-background-individual);
    }

    &--bg-color1 {
      background: $gridBackgroundColor1;
    }

    &--bg-color2 {
      background: $gridBackgroundColor2;
    }

    &--bg-color3 {
      background: $gridBackgroundColor3;
    }

    &--bg-color4 {
      background: $gridBackgroundColor4;
    }

    &--bg-color5 {
      background: $gridBackgroundColor5;
    }

    &--groupedContent1 {
      padding: 20px;
      margin-top: 32px;

      @media (min-width: $screen-laptop) {
        padding: 40px;
        margin-top: 40px;
      }
    }

    &--groupedContent2 {
      padding: 0 20px;

      @media (min-width: $screen-laptop) {
        padding: 0 40px;
      }
    }

    &--groupedContent3 {
      padding: 0 20px 20px;

      @media (min-width: $screen-laptop) {
        padding: 0 40px 40px;
      }

      .grid .row:last-child & {
        margin-bottom: 32px;

        @media (min-width: $screen-laptop) {
          margin-bottom: 40px;
        }
      }
    }
  }

  .row.row--default > .col-xs-12,
  .row.row--colored > .col-xs-12 {
    > .image-text-action-teaser--teaser-wide,
    > .image-text-action-teaser--speech-bubble,
    > .simple-image-teaser,
    > .product-teaser-wide__wrapper {
      @include teaserContainer();
    }
  }

  .row.row--default:not(:first-child) > .col-xs-12,
  .row.row--colored:not(:first-child) > .col-xs-12 {
    > .newsletter-teaser {
      @include teaserContainer();
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  hr {
    border: 0;
    border-bottom: $brownMediumLightColor 1px solid;
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 24px 0;
    }
  }
}
