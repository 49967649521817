body {
  --header-background: #c6492e;
  --footer-background: #c6492e;
  --body-background: #e6d5bf;
  --sand-light-background: #eee1ce image-set(url('../staticImages/bg-sand-light.webp') type("image/webp"), url('../staticImages/bg-sand-light.jpg') type("image/jpeg")); // stylelint-disable-line
  --sand-dark-background: #e6d5bf image-set(url('../staticImages/bg-sand-dark.webp') type("image/webp"), url('../staticImages/bg-sand-dark.jpg') type("image/jpeg")); // stylelint-disable-line
  --brown-background: #743c07 url('../staticImages/bg-brown.webp');
  --teaser-background: linear-gradient(180deg, rgba(255, 255, 255, .75) 0%, #fff 60px, #fff 100%); // stylelint-disable-line
  --teaser-text-color: #2f2f2d;
}
