@font-face {
  font-family: BlackerPro;
  src:
    local('Blacker-Pro-Display-Condensed-Regular'),
    url('../fonts/Blacker-Pro-Display-Condensed-Regular.woff2') format('woff2'),
    url('../fonts/Blacker-Pro-Display-Condensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;  // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: BlackerPro;
  src:
    local('Blacker-Pro-Display-Condensed-Extrabold'),
    url('../fonts/Blacker-Pro-Display-Condensed-Extrabold.woff2') format('woff2'),
    url('../fonts/Blacker-Pro-Display-Condensed-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;  // stylelint-disable-line plugin/no-unsupported-browser-features
}
